import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Row, Col, Button } from 'react-bootstrap';
import { Box } from '../../../modules/common/components/box';
import { SoloCharacter } from '../../../modules/solo/common/components/solo-character';
import { SoloWeapon } from '../../../modules/solo/common/components/solo-weapon';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const SoloGuidesRer: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo solo-guide'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll Guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_reroll.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Solo Leveling: Arise Reroll Guide</h1>
          <h2>
            A Reroll Guide for Solo Leveling: Arise that will help you pick the
            best characters when starting..
          </h2>
          <p>
            Last updated: <strong>11/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Is it worth to reroll?" />
        <p>
          That's the most important question in any gacha games and you{' '}
          <strong>definitely want to reroll in Solo Leveling: Arise</strong>.
          The difference between the weaker and stronger SSR characters (and
          weapons) is quite big, so being able to start with the strongest
          possible options, will make your progression much easier.
        </p>
        <p>
          Furthermore, the game has <strong>in-built reroll system</strong> so
          you basically have to play the tutorial once (takes around 20-30m) and
          then you can restart the account and instantly start pulling again. So
          it's fast and easy to do!
        </p>
        <SectionHeader title="Reroll Methods" />
        <p>
          The first thing you will need to prepare if you plan to reroll is your
          phone/PC or an emulator. Below you can find a comparison of each
          method.
        </p>
        <Row xs={1} xl={2} xxl={2} className="reroll-paths">
          <Col>
            <div className="box">
              <div className="option phone">
                <h6>PC client reroll</h6>
              </div>
              <div className="inside">
                <p>
                  While Solo Leveling: Arise has a <strong>Guest System</strong>{' '}
                  available on the Android/iOS devices, it's not available on
                  the PC client.
                </p>
                <p>
                  This leaves you with a single option if you want to reroll -
                  using new emails to login to the game every time, do 20-30
                  minute long tutorial and then roll. It's long, tedious and
                  totally not worth doing it. Still, if that's your only option
                  here's what you will need to do:
                </p>
                <ul>
                  <li>
                    You have to prepare a bunch of Google emails, login with
                    one, then go through the tutorial (takes around 20-30
                    minutes),
                  </li>
                  <li>Do your pulls,</li>
                  <li>
                    If you didn't get what you wanted, log out and repeat the
                    process.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div className="box">
              <div className="option emulator">
                <h6>Phone/Emulator reroll</h6>
              </div>
              <div className="inside">
                <p>
                  Rerolling on emulators (or your phone) is the{' '}
                  <strong>
                    preferred method of rerolling in Solo Leveling: Arise
                  </strong>
                  .
                </p>
                <p>
                  We suggest using{' '}
                  <strong>
                    BlueStacks for rerolling in Solo Leveling: Arise as the game
                    works very well on it
                  </strong>{' '}
                  and allows you to easily reroll to start strong.
                </p>
                <p>Here's how the process works:</p>
                <ol>
                  <li>Install BlueStacks from the link below this section,</li>
                  <li>Create a new instance,</li>
                  <li>
                    You can download Solo Leveling: Arise either from the store
                    or get an APK from{' '}
                    <a
                      href="https://apkcombo.com/solo-leveling-arise/com.netmarble.sololv/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                    . Simply drag the APK onto your instance to install the
                    game,
                  </li>
                  <li>
                    Install Solo Leveling: Arise on the instance and launch the
                    game - multi-account reroll isn't worth doing as you just
                    simply reset the account instantly (and honestly, trying to
                    finish the tutorial on multiple accounts at the same time
                    will be painful, but you can do it if you want),
                  </li>
                  <li>
                    Download the initial stuff, login as guest and start rolling
                    (process explained below),
                  </li>
                  <li>
                    If you don't get what you want, head to the setting, reset
                    account,
                  </li>
                  <li>Skip the tutorial when prompted,</li>
                  <li>Rinse and repeat.</li>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <p>You can install BlueStacks from our link and support the website!</p>
        <div className="banner countdown">
          <div className="cta">
            <h4>Reroll in Solo Leveling: Arise on PC</h4>
            <OutboundLink href="https://bstk.me/t6T4etbQ4" target="_blank">
              <Button variant="primary">Reroll now on BlueStacks</Button>
            </OutboundLink>
          </div>
        </div>
        <SectionHeader title="Reroll Process" />
        <p>In this section we will explain how the rerolling process works.</p>
        <StaticImage
          src="../../../images/solo/generic/reroll_1.webp"
          alt="Guides"
        />
        <p>
          First of all, make sure to login as Guest when you open the game (
          <strong>this feature isn't available in the PC client</strong>). Now
          you will have to play through the tutorial which will last roughly
          20-30 minutes. There's multiple battles you will have to do and the
          auto feature isn't unlocked until later, so familiarize yourself with
          the game during the process.
        </p>
        <StaticImage
          src="../../../images/solo/generic/reroll_3.webp"
          alt="Guides"
        />
        <p>
          Once you finish the tutorial and finally get freedom of doing what you
          want, go to Settings &gt; Account Settings and there you can find{' '}
          <strong>Redeem Code</strong> option. For active codes check out Solo
          Leveling Arise Homepage or our Discord.
        </p>
        <p>
          Now grab the rewards from the Mail and head to the{' '}
          <strong>Achievements</strong> screen - simply click Claim All there to
          get all of the stuff. After that, go to <strong>Codex</strong> and
          again, click Claim All wherever you see a red dot. Both screens can be
          found in the top right menu.
        </p>
        <p>
          Next, head to the <strong>Draw</strong> screen. There, you will be
          able to setup your wishlist and do the pulls. At this point you should
          have roughly{' '}
          <strong>~8 Custom Draw Tickets and 2000-2500 Essence Stones</strong>{' '}
          (which translates to 8~10 more pulls). You will learn in the next
          section how to setup your wishlist and on what banner to do your
          pulls.
        </p>
        <blockquote>
          <p>
            However, as of the time of this update (August 19, 2024), the 100th
            day celebration is underway. Between the following codes and the
            reward for clearing hard mode Vulcan, players have access to 55~60
            Custom Draw Tickets and 2000-2500 Essence Stones (which translates
            to 8~10 more pulls), making it a prime time to start the game!
          </p>
        </blockquote>
        <div className="codes">
          <Box isCentered>
            <p className="code">SLASUMMEREVENT</p>
            <p className="rewards">10 Custom Draw Tickets</p>
          </Box>
          <Box isCentered>
            <p className="code">HUNTERCOSTUME</p>
            <p className="rewards">1000 Costume Chroma Mats</p>
          </Box>
          <Box isCentered>
            <p className="code">SEEUAGAIN</p>
            <p className="rewards">10 Custom Draw Tickets</p>
          </Box>
          <Box isCentered>
            <p className="code">100DAYTHX</p>
            <p className="rewards">
              1m Gold, 100 Rune Fragment and 100 Powder of Blessing
            </p>
          </Box>
        </div>
        <p>
          Once you're done pulling, head back to the Account Settings and this
          time use the the <strong>Reset Account</strong> option. Once the game
          restarts, select login as Guest Account again, but this time you will
          see the screen below:
        </p>
        <StaticImage
          src="../../../images/solo/generic/reroll_2.webp"
          alt="Guides"
        />
        <p>
          <strong>
            Select Skip Tutorial and you've just saved 30 minutes!
          </strong>{' '}
          You can instantly do pulls again and again and again, till you get who
          you want. And the next section will give you some tips on the reroll
          targets!
        </p>
        <SectionHeader title="Reroll Targets" />
        <p>
          As we previously mentioned, you should have roughly{' '}
          <strong>~16 Summon Tickets and 2000 Essence Stones</strong> (which
          translates to 8 more pulls) that you can spend on pulls. It's not a
          lot, but considering how fast the reroll process is, it could be
          worse. Still, before we talk about the reroll targets, we need to
          explain the banner types available in the game.
        </p>
        <h5>Banner types</h5>
        <StaticImage
          src="../../../images/solo/generic/banner_1.webp"
          alt="Guides"
        />
        <p>
          There are two banner types available in Solo Leveling: Arise:{' '}
          <strong>
            Rate-up banner and Generic banner (called Selection Draw)
          </strong>
          . Rate-up banner features a single character and you have a higher
          chance of obtaining them, while the Generic banner contains a fixed
          set of SSR characters and weapons. What's great though is that the
          General banner has a <strong>wishlist system</strong> where you can
          select up to 4 SSR characters/weapons that you want and you will have
          a higher chance of obtaining them. After roughly a month and half from
          their rate up banner, new characters are added to the standard banner
          pool. This means you can actually save up custom draw tickets in
          advance if you can't pull for a character and then try to get them
          when they are added to the standard banner, even if it has a shared
          rate up with other units.
        </p>
        <p>
          <strong className="red">
            Also, do note that the regular Summon Tickets can't be used on the
            Rate-up Banner. There is a different kind of ticket, the Rate up
            ticket that you can use. You can also use Essence Stones for rate up
            banners.
          </strong>{' '}
          So you will use the Summon Tickets on the General banner and the
          Essence Stones on the Rate-up banner.
        </p>
        <h5>Wishlist</h5>
        <StaticImage
          src="../../../images/solo/generic/banner_2.webp"
          alt="Guides"
        />
        <p>
          You can put up to 4 characters/weapons on the wishlist and here's what
          you should consider placing there.
        </p>
        <h6>
          Characters (priority from left to right - only place 2 on the
          wishlist)
        </h6>
        <p>
          Go Gunhee&gt;Cha Hae In/Amamiya Mirei&gt;Min Byung Gu&gt;Alicia
          Blanche&gt;Han-Se Mi
        </p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="go-gunhee"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
          <SoloCharacter
            slug="cha-hae-in"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
          <SoloCharacter
            slug="amamiya-mirei"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
          <SoloCharacter
            slug="min-byung-gu"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
          <SoloCharacter
            slug="alicia"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
          <SoloCharacter
            slug="han-se-mi"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <ul>
          <li>
            <strong>Go Gunhee</strong>: Gunhee is one of the strongest
            characters in the game, being super tanky while dealing a lot of
            damage. He pairs super well with Thomas Andre if you are rerolling
            for him and is the backbone of the light team
          </li>
          <li>
            <strong>Alicia Blanche</strong>: While she's no longer the queen of
            solo levelling, Alicia remains a super helpful hunter to have since
            she can freeze her enemies and helps dealing damage in water
            advantaged content.
          </li>
          <li>
            <strong>Cha Hae-In/Amamiya Mirei</strong>: Pick one of the two. If
            you are rerolling for Thomas then you should focus on Mirei.
            Otherwise, both options are solid. Cha goes along nicely with Gunhee
            while Mirei will be super helpful when progressing through the game
            and remains a staple for wobl mode.
          </li>
          <li>
            <strong>Min Byung-Gu</strong>: Remains the most general support in
            the game, especially at A1 since he provides much needed crit rate
            to the team. Alternatively, you can also go for A1 Meilin since she
            works well with Thomas and Gunhee while being an amazing support for
            Alicia.
          </li>
          <li>
            <strong>Han Se-Mi</strong>Dedicated Mirei supports, wraps up nicely
            your wind teams considering the game will give you Woo Jinchul with
            his weapon for free.
          </li>
        </ul>
        <h6>Weapons</h6>
        <p>
          With the introduction of the Weapon banner, it becomes easier to get
          your desired weapons. However, there are a few important weapons you
          should consider to wishlist:
        </p>
        <Row xs={1} xxl={2} className="solo-weapons-container">
          <Col>
            <SoloWeapon
              name="Demonic Plum Flower Sword"
              mode="card"
              showLabel
            />
          </Col>
          <Col>
            <SoloWeapon name="Thethis' Grimoire" mode="card" showLabel />
          </Col>
          <Col>
            <SoloWeapon name="Moonshadow" mode="card" showLabel />
          </Col>
          <Col>
            <SoloWeapon name="Demon King's Daggers" mode="card" showLabel />
          </Col>
        </Row>
        <ul>
          <li>
            <strong>Demonic Plum Flower Sword</strong> remains the best - if not
            the best - weapons in the game. You can buff your critical rate and
            setup powerful attacks, making it viable in pretty much every
            scenario. It's highly suggested to keep it in your wishlist until A3
            to make the most ouf ot this weapon.
          </li>
        </ul>
        <p>
          The other generic weapons you should highly consider are Thethis
          Grimoire, Moonshadow and the Demon King Daggers.
        </p>
        <ul>
          <li>
            <strong>Thethis' Grimoire</strong> Provides a very useful freeze
            debuff on top of good damage early on. You can leave it at A1 and
            get more dupes of it later when you start needing more power from
            the debuff it provides.
          </li>
          <li>
            <strong>Moonshadow</strong> A perfect pair for plum, giving you
            extra damage while also slowing the time for the target, helping you
            to get out of sticky situations or go ham unpunished. Moonshadow
            really shines at A3 but should be maxed out at A5 later on given the
            tremendous buff for teamfights, like PoD or guild boss.
          </li>
          <li>
            <strong>Demon King's Dagger</strong> While we are mostly suggesting
            support weapons, DKD deals so much damage that we can't avoid
            mentioning it. It's also an amazing progression weapon, considering
            it will carry you through both the wobl with sufficient advancement.
            Aim for A3
          </li>
        </ul>
        <p>
          <strong>Goal</strong>: Since the rates are pretty low, you should aim
          for a single SSR character or weapon from the roughly 25 pulls you can
          do on both banners. From the characters, both Gunhee and Mirei/Cha
          will be a good start. As for the Weapons, you have a few more options
          that we described above.
        </p>
        <h5>Rate-up</h5>
        <p>
          Since the rate-up banner changes every 3 weeks, do check the tier list
          to learn more about the currently available character there if it's
          worth pulling for!
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier list"
            link="/solo-leveling/tier-list"
            image={
              <StaticImage
                src="../../../images/solo/categories/category_tier.jpg"
                alt="Tier list"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesRer;

export const Head: React.FC = () => (
  <Seo
    title="Reroll Guide | Solo Leveling: Arise | Prydwen Institute"
    description="A Reroll Guide for Solo Leveling: Arise that will help you pick the best characters when starting."
    game="solo"
  />
);
